export enum Record {
    IPFS = "IPFS",
    ARWV = "ARWV",
    SOL = "SOL",
    ETH = "ETH",
    BTC = "BTC",
    LTC = "LTC",
    DOGE = "DOGE",
    Email = "email",
    Url = "url",
    Discord = "discord",
    Github = "github",
    Reddit = "reddit",
    Twitter = "twitter",
    Telegram = "telegram",
    Pic = "pic",
    SHDW = "SHDW",
    POINT = "POINT",
  }